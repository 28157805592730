<template>
<div class="create-activity">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="create-activity-cont">
    <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="0">
      <el-form-item label="" prop="name">
        <div class="tit">活动信息：</div>
        <p class="sub-tit">活动标题：</p>
        <el-input v-model="formValidate.name" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" prop="categoryId">
        <p class="sub-tit">活动专题：</p>
        <el-select v-model="formValidate.categoryId" filterable placeholder="请选择">
          <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="keywords">
        <p class="sub-tit">活动关键词：</p>
        <el-tag
            :key="tag"
            v-for="tag in formValidate.keywords"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            :style="{marginLeft:formValidate.keywords.length ? '10px' : '0'}"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" :style="{marginLeft:formValidate.keywords.length ? '10px' : '0'}" size="small" @click="showInput">+ 添加关键词</el-button>
      </el-form-item>
      <el-form-item label="" prop="date">
        <p class="sub-tit">活动开始-结束时间：</p>
        <el-date-picker
            v-model="formValidate.date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            value-format="timestamp"
            @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="smsNoticeTime">
        <p class="sub-tit">活动短信通知时间：</p>
        <el-date-picker
            v-model="formValidate.smsNoticeTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="timestamp"
            placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="imgUploadfileid">
        <p>宣传图：（尺寸：宽690px*高422px）, jpg/png格式，大小不超过100kb</p>
        <el-upload :action="constant.URL + '/uploadfile/upload'"
                   :data="{
                      type:3
                    }"
                   accept=".jpg,.png,.jpeg,.gif"
                   :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                   :on-success="uploadFileSuccess"
                   :show-file-list="false"
        >
          <div class="upload-btn" v-if="!uploadImg">
            <Icon type="ios-add" :size="50" />
          </div>
          <img :src="uploadImg" width="690" height="422" v-else />
        </el-upload>
      </el-form-item>
      <el-form-item label="" prop="desc">
        <p>详情介绍：（尺寸：图片宽690px * 高度不限）, jpg/png格式，大小不超过300kb</p>
        <Richtxt :height="450" :dataObj="{val:formValidate.desc}" @saveData="saveDescData"></Richtxt>
      </el-form-item>
      <el-form-item label="" prop="joinNotice">
        <p>报名须知：</p>
        <Richtxt :height="450" :dataObj="{val:formValidate.joinNotice}" @saveData="saveJoinNoticeData"></Richtxt>
      </el-form-item>
      <el-form-item label="" prop="dingdingTitle">
        <div class="tit mt20">领取资料文案及二维码（选填）</div>
        <p class="sub-tit">标题（14字以内）：</p>
        <el-input v-model="formValidate.dingdingTitle" maxlength="14" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" prop="dingdingDesc">
        <p class="sub-tit">描述（16字以内）：</p>
        <el-input v-model="formValidate.dingdingDesc" maxlength="16" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" prop="dingdingUploadfileid">
        <p>二维码上传（尺寸：正方形即可）</p>
        <el-upload :action="constant.URL + '/uploadfile/upload'"
                   :data="{
                      type:3
                    }"
                   accept=".jpg,.png,.jpeg,.gif"
                   :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                   :on-success="uploadDingFileSuccess"
                   :show-file-list="false"
        >
          <div class="upload-btn" v-if="!dingdingImg">
            <Icon type="ios-add" :size="50" />
          </div>
          <div class="dingding-img" v-else>
            <img :src="dingdingImg" width="120" height="120"  />
            <img class="dingding-img-close" @click.stop="deleDingdingImg" src="../../assets/images/new_icon/guanbi.png" width="10" height="10" />
          </div>

        </el-upload>
      </el-form-item>
      <el-form-item label="">
        <p class="sub-tit">群号：</p>
        <el-input v-model="formValidate.dingdingNumber" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" prop="organize">
        <div class="tit mt20">主办信息：（展示顺序按照选择顺序展示）</div>
        <el-select v-model="formValidate.organize" multiple filterable placeholder="请选择">
          <el-option
              v-for="item in dataList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="" prop="showApp">
        <div class="tit mt20">活动参与人群设置：</div>
        <div class="sub-tit">选择端</div>
        <el-radio-group v-model="formValidate.showApp" @input="changeShowApp">
          <el-radio :label="item.id" v-for="item in showApp" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="roleIds">
        <div class="sub-tit">选择角色</div>
        <el-radio-group v-model="formValidate.roleIds" :disabled="formValidate.showApp == '2'">
          <el-radio :label="item.id" v-for="item in roleList" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="type">
        <div class="sub-tit">选择类型</div>
        <el-radio-group v-model="formValidate.type" :disabled="formValidate.showApp == '2' || formValidate.showApp == '0'" @input="changeType">
          <el-radio :label="item.id" v-for="item in types" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="jumpCategoryRankListRoute" v-if="formValidate.type == '1'">
        <div class="sub-tit">排行榜链接：（示例："/pagesTwo/pages/rank/index?names=<span class="sub-tit-blue">一级分类名称/二级分类名称/三级分类名称</span>&id=<span class="sub-tit-blue">三级分类id</span>"&nbsp;&nbsp;&nbsp;&nbsp;蓝色字为需要修改的内容）</div>
        <el-input v-model="formValidate.jumpCategoryRankListRoute" size="large" placeholder="请输入排行榜链接" ></el-input>
      </el-form-item>
      <el-form-item label="" prop="jumpCategoryTrainRoute" v-if="formValidate.type == '1'">
        <div class="sub-tit">刷题链接：（示例："/pagesTwo/pages/train/index?categoryId=<span class="sub-tit-blue">三级分类id</span>&dataRule=1"&nbsp;&nbsp;&nbsp;&nbsp;蓝色字为需要修改的内容）</div>
        <el-input v-model="formValidate.jumpCategoryTrainRoute" size="large" placeholder="请输入刷题链接" ></el-input>
      </el-form-item>
      <el-form-item label="" prop="isJumpAfterJoined" v-if="formValidate.type == '0'">
        <div class="sub-tit">报名后跳转视频号：</div>
        <el-radio-group v-model="formValidate.isJumpAfterJoined">
          <el-radio :label="item.id" v-for="item in isJumpAfterJoined" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="wechatVideoId" v-if="formValidate.isJumpAfterJoined == '1'">
        <div class="sub-tit">视频号ID</div>
        <el-input v-model="formValidate.wechatVideoId" size="large" placeholder="请输入视频号ID" ></el-input>
      </el-form-item>
<!--      <el-form-item label="" prop="wechatVideoDataId" v-if="formValidate.isJumpAfterJoined == '1'">-->
<!--        <div class="sub-tit">视频链接ID</div>-->
<!--        <el-input v-model="formValidate.wechatVideoDataId" size="large" placeholder="请输入视频链接ID" ></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="" prop="siteIds">
        <div class="sub-tit">选择查看组织</div>
        <el-checkbox :label="item.id" v-model="item.status" v-for="item in siteList" :key="item.id + 'site'"  @change="changeSite(item)">{{ item.name }}</el-checkbox>
<!--        <el-checkbox-group v-model="formValidate.siteIds" @change="changeSite">-->

<!--        </el-checkbox-group>-->
        <div v-for="item in siteList" :key="item.id">
          <div class="site-type" v-if="item.status">
            <div class="sub-tit">{{ item.name }}</div>
            <el-radio-group v-model="item.type" @input="changeApplyType">
              <el-radio :label="item.id" v-for="item in selectSiteType" :key="item.id">{{ item.name }}</el-radio>
            </el-radio-group>
            <div class="site-select-list mt10" v-if="item.type == 2">
              <div class="site-select-list-add">
                <el-button type="primary" size="small" @click="addSite(item)">+ 添加</el-button>
              </div>
              <div class="site-select-list-tag" v-for="(sItem,sIndex) in item.list" :key="sItem.id">
                <span>{{ sItem.name }}</span><Icon class="ivu-icon ivu-icon-md-close" @click="deleteSelectSite(item.list,sIndex)"></Icon>
              </div>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="">
        <div class="tit">报名成功后添加群二维码</div>
        <el-radio-group v-model="formValidate.applyType" @input="changeApplyType">
          <el-radio :label="item.id" v-for="item in applyTypes" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-if="formValidate.applyType > 0">
        <div class="sub-tit">标题</div>
        <el-input v-model="formValidate.applyShowTitle" size="large" placeholder="请输入活动标题" class="w300"></el-input>

      </el-form-item>
      <el-form-item label="" v-if="formValidate.applyType > 0">
        <div class="sub-tit">群号</div>
        <el-input v-model="formValidate.applyShowNumber" size="large" placeholder="请输入活动标题" class="w300"></el-input>

      </el-form-item>
      <el-form-item label="" prop="applyImg" v-if="formValidate.applyType > 0">
        <div class="sub-tit">上传二维码</div>
        <el-upload :action="constant.URL + '/uploadfile/upload'"
                   :data="{
                      type:3
                    }"
                   accept=".jpg,.png,.jpeg,.gif"
                   :headers="{
                      'access-token':Cookies.get(constant.tokenName)
                    }"
                   :on-success="uploadApplySuccess"
                   :show-file-list="false"
        >
          <div class="upload-btn" v-if="!applyImg">
            <Icon type="ios-add" :size="50" />
          </div>
          <img :src="applyImg" width="120" height="120" v-else />
        </el-upload>

      </el-form-item>
      <el-form-item label="" >
        <p class="sub-tit">活动截至报名条件：</p>
        <el-radio-group v-model="formValidate.applyStopType" :disabled="formValidate.type == '1'">
          <el-radio :label="item.id" v-for="item in applyStopTypeList" :key="item.id">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label=""  v-if="formValidate.applyStopType == 2">
        <p class="sub-tit">活动最大报名人数：</p>
        <el-input v-model="formValidate.allowUserCount" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" >
        <p class="sub-tit">虚拟报名人数：</p>
        <el-input v-model="formValidate.joinUserBaseCount" size="large" placeholder="请输入活动标题" class="w300"></el-input>
      </el-form-item>
      <el-form-item label="" prop="is_show_website">
        <p class="sub-tit">是否显示到官网：</p>
        <el-radio-group v-model="formValidate.is_show_website">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="verifyStatus">
        <p class="sub-tit">是否上架？（若选择是则默认直接发布用户可报名参加，选择否则只保存信息用户无法看到）：</p>
        <el-radio-group v-model="formValidate.verifyStatus">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <Button type="primary" class="mr20" @click="confirm">发布</Button>
      <Button @click="back">取消</Button>
    </div>
  </div>
  <el-dialog
      title="添加"
      :visible.sync="addSiteModal"
      width="1000px"
  >
    <div class="search">
      <div class="search-item">
        <div class="name">
          名称搜索:
        </div>
        <div class="cont">
          <el-input
              placeholder="请输入名称搜索"
              prefix-icon="el-icon-search"
              style="width: 200px"
              v-model="keyword"
              clearable
              @change="searchSite"
          >
          </el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addSiteModal = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmSelectSite" size="small">确 定</el-button>
      </div>
    </div>

    <el-table
        ref="multipleTable"
        :data="siteTableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @select="selectSite"
        @select-all="selectPageSite"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          label="ID"
          prop="id"
          width="80"
          >
      </el-table-column>
      <el-table-column
          prop="name"
          label="组织名称"
          :show-overflow-tooltip="true"
          >
      </el-table-column>
      <el-table-column
          prop="address"
          label="创建时间"
          width="185"
      >
        <template slot-scope="scope">{{ util.timeFormatter(new Date(+scope.row.created_at*1000), 'yyyy-MM-dd hh:mm') }}</template>
      </el-table-column>
    </el-table>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>

  </el-dialog>
</div>
</template>

<script>
import Cookies from "js-cookie";
import Richtxt from '@/components/production/richtxt.vue';
import util from '@/utils/tools.js';
export default {
  name: "create",
  data(){
    // const validateUpload = (rule, value, callback)=>{
    //   this.$nextTick(()=>{
    //     if (!this.uploadImg) {
    //       return callback(new Error('请上传宣传图'));
    //     }else{
    //       callback();
    //     }
    //   })
    // }
    // const validateDingUpload = (rule, value, callback)=>{
    //   this.$nextTick(()=>{
    //     console.log('111111',this.dingdingImg)
    //     if (!this.dingdingImg) {
    //       return callback(new Error('请上传报名成功二维码'));
    //     }else{
    //       callback();
    //     }
    //   })
    // }
    const validateDingUpload = (rule, value, callback)=>{
      this.$nextTick(()=>{
        if (!this.dingdingImg && this.dingdingImgIsRequire) {
          return callback(new Error('请上传二维码'));
        }else{
          callback();
        }
      })
    }
    const validateJoinNotice = (rule, value, callback)=>{
      let str = value.replace(/&nbsp;/g,'').replace(/ /g,'').trim();
      if (!str || str === '<p></p>') {
        return callback(new Error('请输入报名须知'));
      }else{
        callback();
      }
    }
    return{
      util:util,
      Cookies:Cookies,
      formValidate: {
        name: '',
        categoryId:'',
        date:[],
        smsNoticeTime:'',
        imgUploadfileid: '',
        desc:'',
        dingdingTitle:'',
        dingdingDesc:'',
        organize:[],
        dingdingNumber:'',
        dingdingUploadfileid:'',
        verifyStatus:'1',
        applyStopType:'0',
        allowUserCount:'',
        joinUserBaseCount:'',
        applyType:'0',
        applyShowTitle:'',
        applyShowNumber:'',
        applyShowUploadfileid:'',
        applyImg:'',
        roleIds:'0',
        siteIds:[],
        showApp:'0',
        type:'0',
        jumpCategoryRankListRoute:'',
        jumpCategoryTrainRoute:'',
        keywords:[],
        joinNotice:'',
        isJumpAfterJoined:'',
        wechatVideoDataId:'',
        wechatVideoId:'',
        is_show_website:'1',  //是否在官网展示 0不展示 1展示
      },
      ruleValidate: {
        name: [
          { required: true, message: '请输入活动标题', trigger: 'blur' }
        ],
        categoryId:[
          { required: true, message: '请选择活动专题', trigger: 'change' }
        ],
        keywords:[
          { type:'array', required: true, message: '请输入刷题链接', trigger: 'blur' }
        ],
        date:[
          { required: true, message: '请选择活动开始-结束时间', trigger: 'change' }
        ],
        smsNoticeTime:[
          { required: true, message: '请选择活动短信通知时间', trigger: 'change' }
        ],
        imgUploadfileid:[
          { required: this.dataId ? true : false, message: '请上传宣传图' }
        ],
        desc:[
          { required: true, message: '请输入详情介绍', trigger: 'blur' }
        ],
        joinNotice:[
          { required: true, message: '请输入报名须知', validator: validateJoinNotice, trigger: 'blur' }
        ],
        organize:[
          { required: true, message: '请选择主办方', trigger: 'change' }
        ],
        dingdingUploadfileid:[
          {  message: '请上传二维码',tigger:'change',validator: validateDingUpload,  }
        ],
        applyImg:[
          { required: true, message: '请上传二维码',tigger:'change'  }
        ],
        roleIds:[
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
        siteIds:[
          { required: true, message: '请选择查看组织', trigger: 'change' }
        ],
        showApp:[
          { required: true, message: '请选择端', trigger: 'change' }
        ],
        type:[
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        jumpCategoryRankListRoute:[
          { required: true, message: '请输入排行榜链接', trigger: 'blur' }
        ],
        jumpCategoryTrainRoute:[
          { required: true, message: '请输入刷题链接', trigger: 'blur' }
        ],
        isJumpAfterJoined:[
          { required: true, message: '请选择是否跳转视频号', trigger: 'change' }
        ],
        wechatVideoDataId:[
            { required: true, message: '请输入视频链接ID', trigger: 'blur' }
        ],
        wechatVideoId:[
            { required: true, message: '请输入视频号ID', trigger: 'blur' }
        ],
      },
      applyType:false,
      uploadImg:'',
      dingdingImg:'',
      applyImg:'',
      dataList:[],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
      },
      dataId:'',
      applyStopTypeList:[],
      applyTypes:[],
      roleList:[],
      siteList:[],
      addSiteModal:false,
      siteTableData:[],
      total:0,
      page:1,
      selectSiteType:[
        {
          name:'全部',
          id:'1',
        },
        {
          name:'定向',
          id:'2',
        },
      ],
      curSite:{},
      keyword:'',
      loading:false,
      catchSelectSite:{},
      curPageSelectSite:[],
      types:[],
      showApp:[],
      categoryList:[],
      keywords: [],
      inputVisible: false,
      inputValue: '',
      isJumpAfterJoined:[
        {
          name:'不跳转',
          id:'0'
        },
        {
          name:'跳转',
          id:'1'
        }
      ]
    }
  },
  computed:{
    dingdingImgIsRequire(){
      if(this.formValidate.dingdingTitle || this.formValidate.dingdingDesc){
        if(this.dingdingImg){
          return false;
        }else{
          return true;
        }
      }else{
        return false;
      }
    }
  },
  components:{
    Richtxt
  },
  created(){
    this.dataId = this.$route.query.id || '';
    this.getForm();
    this.getList();
    this.getCategoryList();

  },
  methods:{
    getCategoryList(){
      this.api.activity.activityCategoryList().then((res)=>{
        this.categoryList = res.list;
      })
    },
    handleClose(tag) {
      this.formValidate.keywords.splice(this.formValidate.keywords.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      let arr = this.formValidate.keywords.filter((item)=>{
        return item == inputValue;
      });
      if(arr.length){
        this.$Message.warning('关键词已存在');
      }else{
        if (inputValue) {
          this.formValidate.keywords.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      }

    },
    getForm(){
      this.api.activity.activityForm().then((res)=>{
        this.applyStopTypeList = [];
        for(let name in res.applyStopTypes){
          this.applyStopTypeList.push({
            name:res.applyStopTypes[name],
            id:name
          })
        }
        this.applyTypes = [];
        for(let name in res.applyTypes){
          this.applyTypes.push({
            name:res.applyTypes[name],
            id:name
          })
        }
        this.siteList = [];
        for(let name in res.siteTypes){
          this.siteList.push({
            name:res.siteTypes[name],
            id:name,
            status:false,
            type:'0',
            list:[],
          })
        }
        this.roleList = [];
        for(let name in res.showUserRoles){
          this.roleList.push({
            name:res.showUserRoles[name],
            id:name
          })
        }
        this.showApp = [];
        for(let name in res.showApp){
          this.showApp.push({
            name:res.showApp[name],
            id:name
          })
        }

        this.types = [];
        for(let name in res.types){
          this.types.push({
            name:res.types[name],
            id:name
          })
        }
        if(this.dataId){
          this.getDetail();
        }
      })
    },
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.api.activity.detail(params).then((res)=>{
        this.formValidate.name = res.info.name;
        this.formValidate.date = [new Date(res.info.start_time * 1000),new Date(res.info.end_time * 1000)]
        this.formValidate.desc = res.info.desc;
        this.formValidate.dingdingTitle = res.info.dingding_title;
        this.formValidate.dingdingDesc = res.info.dingding_desc;
        this.formValidate.dingdingNumber = res.info.dingding_number;
        this.formValidate.dingdingUploadfileid = res.info.dingding_uploadfileid;
        this.formValidate.imgUploadfileid = res.info.img_uploadfileid;
        this.formValidate.smsNoticeTime = res.info.sms_notice_time * 1000;
        this.formValidate.organize = res.info.organizeList.map((item)=>{
          return item.organize_id;
        });
        this.formValidate.verifyStatus = res.info.verify_status;
        this.formValidate.is_show_website = res.info.is_show_website;

        this.formValidate.applyStopType = res.info.apply_stop_type;
        this.formValidate.allowUserCount = res.info.allow_user_count;
        this.formValidate.joinUserBaseCount = res.info.join_user_base_count;


        this.formValidate.applyType = res.info.apply_type;
        this.applyType = res.info.apply_type != '0' && !this.dataId;
        this.formValidate.applyShowNumber = res.info.apply_show_number;
        this.formValidate.applyShowTitle = res.info.apply_show_title;
        this.formValidate.applyShowUploadfileid = '';
        this.formValidate.applyImg = res.info.apply_show_qrcode;

        this.formValidate.roleIds = res.info.show_user_role;
        this.formValidate.siteIds = res.info.show_site_type.split(',');
        this.formValidate.showApp = res.info.show_app || '0';
        this.formValidate.type = res.info.type || '0';
        this.formValidate.jumpCategoryRankListRoute = res.info.jump_category_rank_list_route || '';
        this.formValidate.jumpCategoryTrainRoute = res.info.jump_category_train_route || '';

        this.formValidate.categoryId = res.info.category_id == '0' ? '' : res.info.category_id;
        this.formValidate.keywords = res.info.keywords ? res.info.keywords.split(',') : [];
        this.formValidate.joinNotice = res.info.join_notice || '';
        this.formValidate.isJumpAfterJoined = res.info.is_jump_after_joined || '';
        this.formValidate.wechatVideoId = res.info.wechat_video_id || '';
        this.formValidate.wechatVideoDataId = res.info.wechat_video_data_id || '';


        this.siteList.forEach((item)=>{
          let arr = this.formValidate.siteIds.filter((sItem)=>{
            return sItem == item.id;
          })
          if(arr.length){
            item.status = true;
          }else{
            item.status = false;
          }
          if(item.id == 0){ //院校
            item.type=res.info.school_range_type;
          }else if(item.id == 1){   //经销商
            item.type=res.info.broker_range_type;
          }else if(item.id == 2){ //企业
            item.type=res.info.org_range_type;
          }
          let siteArr = res.info.site_list.filter((sItem)=>{
            return sItem.type == item.id;
          })
          if(siteArr.length){
            item.list = siteArr[0].list;
          }else{
            item.list = [];
          }

        })

        this.uploadImg = res.info.img;
        this.dingdingImg = res.info.dingding_qrcode;
        this.applyImg = res.info.apply_show_qrcode;
      })
    },
    saveDescData(data){
      this.formValidate.desc = data;
    },
    saveJoinNoticeData(data){
      this.formValidate.joinNotice = data;
    },
    changeDate(){
      if(this.formValidate.date[0]){
        this.formValidate.smsNoticeTime = this.formValidate.date[0] - (3 * 60 * 60 * 1000);
      }
    },
    getList(){  //获取列表数据
      this.api.activity.organizeList().then((res)=>{
        this.dataList = res.list;
      })
    },
    changeApplyType(){
      this.formValidate.applyShowNumber = '';
      this.formValidate.applyShowTitle = '';
      this.formValidate.applyShowUploadfileid = '';
      this.applyImg = '';
      this.applyType = this.formValidate.applyType != '0' && !this.dataId;
    },
    uploadFileSuccess(response,file, fileList){  //上传文件成功
      this.formValidate.imgUploadfileid = response.data.info.id;
      this.uploadImg = response.data.info.upload_path;
      this.$refs.formValidate.validateField('imgUploadfileid');
    },
    uploadDingFileSuccess(response,file, fileList){  //上传文件成功
      this.formValidate.dingdingUploadfileid = response.data.info.id;
      this.dingdingImg = response.data.info.upload_path;
      this.$refs.formValidate.validateField('dingdingUploadfileid');
    },
    uploadApplySuccess(response,file, fileList){  //上传文件成功
      this.formValidate.applyShowUploadfileid = response.data.info.id;
      this.applyImg = response.data.info.upload_path;
      this.formValidate.applyImg = this.applyImg;
      this.$refs.formValidate.validateField('applyShowUploadfileid');
    },
    confirm(){
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          let schoolRangeType = this.siteList.filter((item)=>{
            return item.id == '0';
          })[0].type;
          let brokerRangeType = this.siteList.filter((item)=>{
            return item.id == '1';
          })[0].type;
          let orgRangeType = this.siteList.filter((item)=>{
            return item.id == '2';
          })[0].type;
          let siteIds = [];
          console.log(this.siteList,'this.siteList')
          this.siteList.forEach((item)=>{
            if(item.status){
              let arr = item.list.map((sItem)=>{
                return sItem.id;
              });
              console.log(arr,'arrarr')
              siteIds = siteIds.concat(arr);
            }
          })
          console.log(siteIds,'siteIds')
          let params = {
            name:this.formValidate.name,
            start_time:this.formValidate.date[0]/1000,
            end_time:this.formValidate.date[1]/1000,
            desc:this.formValidate.desc,
            dingding_title:this.formValidate.dingdingTitle,
            dingding_desc:this.formValidate.dingdingDesc,
            dingding_number:this.formValidate.dingdingNumber,
            dingding_uploadfileid:this.formValidate.dingdingUploadfileid,
            img_uploadfileid:this.formValidate.imgUploadfileid,
            sms_notice_time:this.formValidate.smsNoticeTime/1000,
            organize:this.formValidate.organize,
            verify_status:this.formValidate.verifyStatus,
            is_show_website:this.formValidate.is_show_website,
            apply_stop_type:this.formValidate.applyStopType,
            allow_user_count:this.formValidate.allowUserCount,
            join_user_base_count:this.formValidate.joinUserBaseCount,
            apply_type:this.formValidate.applyType,
            show_user_role:this.formValidate.roleIds,
            show_site_type:this.formValidate.siteIds.join(','),
            school_range_type:schoolRangeType,
            broker_range_type:brokerRangeType,
            org_range_type:orgRangeType,
            site_ids:siteIds.join(','),
            type:this.formValidate.type,
            show_app:this.formValidate.showApp,
            jump_category_train_route:this.formValidate.jumpCategoryTrainRoute,
            jump_category_rank_list_route:this.formValidate.jumpCategoryRankListRoute,
            category_id:this.formValidate.categoryId,
            keywords:this.formValidate.keywords.join(','),
            join_notice:this.formValidate.joinNotice,
            is_jump_after_joined:this.formValidate.isJumpAfterJoined,
            wechat_video_id:this.formValidate.wechatVideoId,
            wechat_video_data_id:this.formValidate.wechatVideoDataId
          }
          if(this.formValidate.applyType > 0){
            params.apply_show_number = this.formValidate.applyShowNumber;
            params.apply_show_title = this.formValidate.applyShowTitle;
            params.apply_show_uploadfileid = this.formValidate.applyShowUploadfileid;
          }
          if(this.dataId){
            params.id = this.dataId;
          }
          console.log(params,'params')
          this.commitLoading = true;
          if(this.dataId){
            this.api.activity.update(params).then((res)=>{
              this.commitLoading = false;
              this.$Message.success('操作成功!');
              this.$router.go(-1);
            }).catch((err)=>{
              this.commitLoading = false;
            })
          }else{
            this.api.activity.create(params).then((res)=>{
              this.commitLoading = false;
              this.$Message.success('操作成功!');
              this.$router.go(-1);
            }).catch((err)=>{
              this.commitLoading = false;
            })
          }

        }else{
          this.$Message.warning('请填写完整后提交数据～');
        }
      })
    },
    back(){
      this.$router.go(-1);
    },
    deleDingdingImg(){
      this.dingdingImg = '';
      this.formValidate.dingdingUploadfileid = '';
    },
    getSiteList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
        type:this.curSite.id,
      };

      this.api.site.siteList(params).then((res)=>{
        this.loading = false;
        this.siteTableData = res.list;

        this.$nextTick(()=>{
          this.siteTableData.forEach((item)=>{
            let arr = this.curSite.list.filter((sItem)=>{
              return sItem.id == item.id;
            })
            if(arr.length){
              this.$refs.multipleTable.toggleRowSelection(item,true);
            }
          })
        })

        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      // this.formatSelectSite();
      // this.curPageSelectSite = [];
      this.page = page;
      this.getSiteList();
    },
    selectSite(selection,row){
      console.log(selection,row)
      if(this.curPageSelectSite.length > selection.length){ //删除了一项
        this.formatSelectSite(row,'remove')
      }else{
        this.formatSelectSite(row,'add')
      }
      this.curPageSelectSite = selection;

    },
    formatSelectSite(row,type){
      if(type == 'remove'){
        let index = this.catchSelectSite.findIndex((sItem)=>{
          return sItem.id == row.id;
        });
        if(index > -1){
          this.$delete(this.catchSelectSite,index);
        }
      }else{
        this.catchSelectSite.push(row);
      }
    },
    selectPageSite(selection){
      if(selection.length){ //添加
        selection.forEach(item=>{
          let index = this.catchSelectSite.findIndex((sItem)=>{
            return sItem.id == item.id;
          });
          if(index == -1){
            this.catchSelectSite.push(item);
          }
        })
      }else{  //删除
        this.siteTableData.forEach(item=>{
          let index = this.catchSelectSite.findIndex((sItem)=>{
            return sItem.id == item.id;
          });
          if(index > -1){
            this.$delete(this.catchSelectSite,index);
          }
        })
      }
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getSiteList();
    },
    changeSite(data){
      if(data.status){
        data.status = true;
        data.type = '1';
        this.formValidate.siteIds.push(data.id);
      }else{
        data.status = false;
        data.type = '0';
        data.list = [];
        let index = this.formValidate.siteIds.findIndex((item)=>{
          return item == data.id
        });
        this.$delete(this.formValidate.siteIds,index);
      }
    },
    addSite(data){
      this.curSite = data;
      this.addSiteModal = true;
      this.keyword = '';
      this.page = 1;
      this.catchSelectSite = JSON.parse(JSON.stringify(data.list));
      this.getSiteList();
    },
    searchSite(){
      this.page = 1;
      this.getSiteList();
    },
    // handleSelectionChange(data){
    //   this.curPageSelectSite = data;
    // },
    confirmSelectSite(){
      // this.formatSelectSite();
      // this.curPageSelectSite = [];
      let list = [];
      for(let name in this.catchSelectSite){
        list = list.concat(this.catchSelectSite[name])
      }
      this.curSite.list = list;
      this.addSiteModal = false;
    },
    deleteSelectSite(data,index){
      this.$delete(data,index);
    },
    changeShowApp(data){
      if(data == '0'){  //学生端与教师端都显示  只能是直播活动
        this.formValidate.type = '0';
      }else if(data == '2'){ //只有教师端显示
        this.formValidate.type = '0';
        this.formValidate.roleIds = '2'
      }
    },
    changeType(data){
      if(data == '1'){
        this.formValidate.applyStopType = '1';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.create-activity{
  margin: 20px;
  font-size: 14px;
  .create-activity-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    font-size: 14px;

    .tit{
      color:#4E83FF;
      font-size: 16px;
      font-weight: bold;
    }

    .sub-tit-blue{
      color:#4E83FF;
    }
    .site-type{
      margin-bottom: 20px;
      padding: 20px;
      background: #F6F7FA !important;
      .sub-tit{
        font-weight: bold;
      }
      .site-select-list{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .site-select-list-add {
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 5px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .site-select-list-tag{
          margin-right: 10px;
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 10px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #dcdee2;
          border-radius: 4px;
          cursor: pointer;

          >span {
            margin-right: 10px;
          }
        }
      }
    }
    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      vertical-align: bottom;
    }
  }
}
.upload-btn{
  width: 120px;
  height: 120px;
  border: 1px solid #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dingding-img{
  position: relative;
  width: 120px;
  height: 120px;

  .dingding-img-close{
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.search{
  font-size: 14px;
  color:#333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-item{
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;

    .name{
      padding: 5px 0;
      text-align: right;
      font-weight: bold;
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 0 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          font-weight: bold;
          color: #4A79FF;
        }
      }
    }
  }
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
